<template>
  <div class="moreTemplates">
    <div class="searchTitle">
      <span class="text">推荐</span>
      <div class="searchContent">
        <el-input v-model="searchValue" placeholder="请输入内容"></el-input>
        <el-button type="primary" icon="el-icon-search">搜索</el-button>
      </div>
    </div>
    <div class="templates">
      <el-row :gutter="20">
        <el-col :span="6" v-for="m in modulesInfo.result" :key="m.key">
          <TemplateC
            :template="m"
            :deleteCommonTemplate="deleteCommonTemplate"
            :useCommonTemplate="useCommonTemplate"
          />
        </el-col>
      </el-row>
    </div>
    <Pagination
      v-if="modulesInfo.total > 8"
      :handleSizeChange="handleSizeChange"
      :handleCurrentPageChange="handleCurrentPageChange"
      :total="modulesInfo.total"
    />
  </div>
</template>

<script>
import TemplateC from "@/components/Template";
import Pagination from "@/components/Pagination";
import { mapState } from "vuex";

export default {
  components: {
    TemplateC,
    Pagination,
  },
  data() {
    return {
      searchValue: "",
      templates: [
        {
          text: "多媒体教室",
          picture:
            "https://q-extra.paixin.com/default/2021/1005/c341fdaaf79c987a1a3544220d8efbff.jpg",
        },
        {
          text: "企业展厅",
          picture:
            "https://q-extra.paixin.com/default/2021/1005/c341fdaaf79c987a1a3544220d8efbff.jpg",
        },
        {
          text: "党建馆",
          picture:
            "https://q-extra.paixin.com/default/2021/1005/c341fdaaf79c987a1a3544220d8efbff.jpg",
        },
        {
          text: "会议室",
          picture:
            "https://q-extra.paixin.com/default/2021/1005/c341fdaaf79c987a1a3544220d8efbff.jpg",
        },
        {
          text: "视频控制",
          picture:
            "https://q-extra.paixin.com/default/2021/1005/c341fdaaf79c987a1a3544220d8efbff.jpg",
        },
        {
          isAdd: true,
        },
      ],
      currentPage4: 1,
      modulesInfo: {
        result: [],
      },
    };
  },
  computed: {
    ...mapState(["projectLevels"]),
  },
  mounted() {
    this.getCommonTemplatesList({});
    this.$store.dispatch("getProjectLevelsAction", {});
  },
  methods: {
    handleSizeChange(v) {
      this.getCommonTemplatesList({ page_size: v });
    },
    handleCurrentPageChange(v) {
      this.getCommonTemplatesList({ page_num: v });
    },
    getCommonTemplatesList({ page_size = 8, page_num = 1 }) {
      this.$request
        .queryCommonTemplates({ page_size, page_num })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.modulesInfo = {
            result: (res?.data?.result || []).map((t) => ({
              text: t.project_name,
              picture: t.project_img,
              type: t.type,
              id: t.id,
              fileType: "image",
            })),
          };
        });
    },
    deleteCommonTemplate(id) {
      this.$request.deleteCommonTemplates({ ids: [id] }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.$message.error("删除成功");
        this.getCommonTemplatesList({});
      });
    },
    useCommonTemplate(id, type) {
      const { btns, pages, ip_ports, project_code } = this.projectLevels.find(
        (l) => l.project_code == type
      );
      const limits = btoa(
        JSON.stringify({ project_code, pages, btns, ip_ports })
      );
      this.$router.push({
        path: "/projects/projectsEdit",
        query: {
          limits,
          id,
          isCommonTemplate: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.moreTemplates {
  background-color: #fff;
  width: 100%;
  padding-bottom: 24px;

  .searchTitle {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #e6e6e6;
    .text {
      color: rgb(80, 165, 205);
      font-size: 18px;
    }
  }
  .searchContent {
    display: flex;
    .el-button {
      margin-left: 10px;
    }
  }
  .templates {
    margin: 24px;
  }
}
</style>